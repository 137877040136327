import React from 'react'
import Layout from '../layouts'

const Thank = () => (
	<Layout head={{ title: 'Thank you' }}>
		<div className="py-5 bg-white">
			<div className="container pt-5">
				<div class="slim-content text-center pt-5">
					<h1>Thank You</h1>
					<p>We have received your message and will get back to you soon.</p>
					<div>
						<a href="/" class="btn btn-primary red-btn">
							Home
						</a>
					</div>
				</div>
			</div>
		</div>
	</Layout>
)

export default Thank
